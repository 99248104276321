import React, { useState } from 'react';
import "./css/popup.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../services/UserService';
import axios from 'axios';
import { getNotification } from '../Redux/Action/action';

const { REACT_APP_REDIRECT_URI, REACT_APP_ALL_CAMERA_URL } = process.env
const Popup = ({ data, onConfirm, onCancel, proceed, }) => {
    const state = useSelector(state => state.stateReducer)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPopup, setShowPopup] = useState(true);
    const [handleOther, setHandleOther] = useState(false)
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    let rtsp_url = data.rtsp_url ? data.rtsp_url : ""

    const handleConfirm = () => {
        setShowPopup(false);
        // sendData()
        onConfirm();
    };

    const handleCancel = () => {
        setShowPopup(false);
        onCancel();
    };


    function validateInputs(ID) {
        let isValid = true;

        // Validate Channel ID
        const channelIDInput = document.getElementById("channel_ID");
        if (channelIDInput.value.trim() === "") {
            channelIDInput.classList.add("is-invalid");
            isValid = false;
        } else {
            channelIDInput.classList.remove("is-invalid");
            channelIDInput.classList.add("is-valid");
        }

        // Validate Channel Name
        const channelNameInput = document.getElementById("channel_name");
        if (channelNameInput.value.trim() === "") {
            channelNameInput.classList.add("is-invalid");
            isValid = false;
        } else {
            channelNameInput.classList.remove("is-invalid");
            channelNameInput.classList.add("is-valid");
        }

        // Validate IP Address
        const ipAddressInput = document.getElementById("ip_address");
        if (ipAddressInput.value.trim() === "") {
            ipAddressInput.classList.add("is-invalid");
            isValid = false;
        } else {
            ipAddressInput.classList.remove("is-invalid");
            ipAddressInput.classList.add("is-valid");
        }

        // Validate User ID
        const userIdInput = document.getElementById("user_id");
        if (userIdInput.value.trim() === "") {
            userIdInput.classList.add("is-invalid");
            isValid = false;
        } else {
            userIdInput.classList.remove("is-invalid");
            userIdInput.classList.add("is-valid");
        }

        // Validate Port Number (not empty)
        const portNumberInput = document.getElementById("port_number");
        if (portNumberInput.value.trim() === "") {
            portNumberInput.classList.add("is-invalid");
            isValid = false;
        } else {
            portNumberInput.classList.remove("is-invalid");
            portNumberInput.classList.add("is-valid");
        }

        if (data.id === "") {
            // Validate Password
            const passwordInput = document.getElementById("cam_pwd");
            if (passwordInput.value.trim() === "") {
                passwordInput.classList.add("is-invalid");
                isValid = false;
            } else {
                passwordInput.classList.remove("is-invalid");
                passwordInput.classList.add("is-valid");
            }
        }

        // Validate Status
        const statusInput = document.getElementById("cam_status");
        if (statusInput.value === "") {
            statusInput.classList.add("is-invalid");
            isValid = false;
        } else {
            statusInput.classList.remove("is-invalid");
            statusInput.classList.add("is-valid");
        }



        isValid = validateRtspUrl() && isValid;

        // If everything is valid
        if (isValid) {
            sendData(ID)
        }
    }

    function validateRtspUrl() {
        let isValid = true;

        // If the "Other" input field is being used, validate it
        if (handleOther) {
            const otherRtspInput = document.getElementById("other_rtsp_url");
            if (otherRtspInput.value.trim() === "") {
                otherRtspInput.classList.add("is-invalid");
                isValid = false;
            } else {
                otherRtspInput.classList.remove("is-invalid");
                otherRtspInput.classList.add("is-valid");
            }
        } else {
            // If the select dropdown is being used, validate it
            const rtspSelect = document.getElementById("rtsp_url");
            if (rtspSelect.value === "") {
                rtspSelect.classList.add("is-invalid");
                isValid = false;
            } else {
                rtspSelect.classList.remove("is-invalid");
                rtspSelect.classList.add("is-valid");
            }
        }

        // Return overall validity for this field
        return isValid;
    }

    const sendData = async (cameraID) => {
        if (UserService.isLoggedIn()) {

            const channel_ID = document.getElementById("channel_ID").value
            const channel_name = document.getElementById("channel_name").value
            const ip_address = document.getElementById("ip_address").value
            const port_number = document.getElementById("port_number").value
            const user_id = document.getElementById("user_id").value
            const cam_pwd = document.getElementById("cam_pwd").value
            // const rtsp_url = document.getElementById("rtsp_url").value
            const cam_status = document.getElementById("cam_status").value

            try {
                // setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();

                sendData.append("id", data.id ? data.id : "");
                sendData.append("channel_id", channel_ID);
                sendData.append("channel_name", channel_name);
                sendData.append("ip_address", ip_address);
                sendData.append("port_number", port_number)
                sendData.append("user_id", user_id);
                sendData.append("cam_pwd", cam_pwd);
                sendData.append("rtsp_url", rtsp_url)
                sendData.append("cam_status", cam_status)


                const config = {
                    method: "post",
                    url: `${REACT_APP_ALL_CAMERA_URL}/add_camera`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                // setHandleSubmit(true);
                console.log("Started");
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    // console.log(getResponse.data)
                    dispatch(
                        getNotification({
                            message: "Data Saved successfully",
                            type: "success",
                        })
                    );
                    handleConfirm()

                }
            } catch (err) {
                console.log('error', err);
                onCancel();
                // setLoaderAgreement(false)
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/configuration` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            // setLoaderAgreement(false);
        }
        else if (!UserService.isLoggedIn()) {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI}/configuration`,
                });
            }, 3000);
        }
        // setLoaderAgreement(false);
    };




    return (
        <>
            {showPopup && (
                <div className="popup-overlay alert alert-secondary overflow-y-scroll" role="alert">
                    <div className="popup-container p-5 position-relative w-1/2">
                        <i onClick={handleCancel} className='far fa-window-close fa-2x position-absolute top-2 right-5 cursor-pointer'></i>
                        <h5 className="mb-2 text-center">Configuration</h5>
                        <div className='space-x-5 text-center'>
                            {/* {!proceed && <button onClick={handleConfirm} className="btn btn-danger my-3 px-4">Delete <i className='fas fa-trash'></i></button>}
                            {proceed && <button onClick={handleConfirm} className="btn btn-primary my-3 px-4">Proceed <i className='fas fa-check'></i></button>}
                            <button onClick={handleCancel} className="btn btn-danger my-3 px-4">Cancel <i className='fas fa-window-close'></i></button> */}
                        </div>
                        <div className='space-x-5 text-center'>
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td className="pt-3 w-50" scope="row">Channel ID</td>
                                        <td>
                                            <input id="channel_ID" defaultValue={data.channel_id} className="form-control w-full rounded" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pt-3" scope="row">Channel Name</td>
                                        <td>
                                            <input id="channel_name" defaultValue={data.channel_name} className="form-control w-full rounded" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pt-3" scope="row">IP Address</td>
                                        <td>
                                            <input id="ip_address" defaultValue={data.ip_address} className="form-control w-full rounded" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pt-3" scope="row">User ID</td>
                                        <td>
                                            <input id="user_id" defaultValue={data.user_id} className="form-control w-full rounded" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pt-3" scope="row">Port No.</td>
                                        <td>
                                            <input id="port_number" defaultValue={data.port_nmuber ? data.port_nmuber : "554"} className="form-control w-full rounded" type="text" />
                                        </td>
                                    </tr>
                                    <tr className={data.id ? "d-none" : ""}>
                                        <td className="pt-3" scope="row">Password</td>
                                        <td>
                                            <div className="relative">
                                                <input
                                                    id="cam_pwd"
                                                    defaultValue={data.cam_pwd}
                                                    className="form-control w-full rounded pr-10"
                                                    type={showPassword ? "text" : "password"}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={togglePasswordVisibility}
                                                    className="absolute right-2 top-2 text-gray-500 focus:outline-none"
                                                >
                                                    {showPassword ? (
                                                        <i className="fas fa-eye-slash"></i>  // Font Awesome icon
                                                    ) : (
                                                        <i className="fas fa-eye"></i>
                                                    )}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pt-3" scope="row">RTSP URL</td>
                                        <td>
                                            {handleOther ? (
                                                <span className="flex relative">
                                                    <input
                                                        onChange={(e) => {
                                                            rtsp_url = e.target.value;
                                                        }}
                                                        defaultValue={data.rtsp_url}
                                                        id="other_rtsp_url"
                                                        className="form-control w-full rounded"
                                                        type="text"
                                                    />
                                                    <i
                                                        onClick={() => setHandleOther(false)}
                                                        className="fas fa-redo absolute right-2 top-3.5 cursor-pointer"
                                                        title="Reset"
                                                    ></i>
                                                </span>
                                            ) : (
                                                <select
                                                    onChange={(e) => {
                                                        if (e.target.value === "value-1")
                                                            rtsp_url = "rtsp://uid:pwd@ip:port_number/streaming/Channels/channel_id";
                                                        else if (e.target.value === "value-2")
                                                            rtsp_url = "rtsp://uid:pwd@ip:port_number/cam/realmonitor?channel=channel_id&subtype=0";
                                                        else if (e.target.value === "other") setHandleOther(true);
                                                    }}
                                                    id="rtsp_url"
                                                    defaultValue={data.rtsp_url}
                                                    className="form-control w-full rounded text-center pr-5"
                                                >
                                                    <option value="" selected disabled>
                                                        --Select RTSP url--
                                                    </option>
                                                    <option value="value-1">
                                                        rtsp://uid:pwd@ip:port_number/streaming/Channels/channel_id
                                                    </option>
                                                    <option value="value-2">
                                                        rtsp://uid:pwd@ip:port_number/cam/realmonitor?channel=channel_id&subtype=0
                                                    </option>
                                                    <option value="other">Other</option>
                                                </select>
                                            )}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className="pt-3" scope="row">Status</td>
                                        <td>
                                            <select
                                                defaultValue={data.cam_status}
                                                id="cam_status" className="form-control w-full rounded text-center pr-5">
                                                <option value="active">Active</option>
                                                <option value="inactive">Inactive</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                            <div className='text-center w-full'>
                                <button onClick={() => validateInputs(data.id)} className='btn btn-primary w-full -ml-9 mt-2'>{data.id ? "Update" : "Save"}</button>
                            </div>
                        </div>
                    </div>
                </div >
            )}
        </>
    );
};

export default Popup;