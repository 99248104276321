import React, { useEffect } from 'react'
import UserService from '../services/UserService'

const { REACT_APP_REDIRECT_URI } = process.env
function SignUp() {
    useEffect(() => {
        UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/" })
    }, [])
    return (
        <></>
    )
}

export default SignUp