import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
// import 'tailwindcss/tailwind.css';
import { BrowserRouter, Route, Routes, json } from "react-router-dom";
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated";

import ErrorPage from "./components/ErrorPage";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import 'spiketip-tooltip/spiketip.min.css'

import SignUp from "./components/SignUp";
import DashBoard from "./components/DashBoard";
import Charts from "./components/Charts";
import Navbar from "./components/Navbar";
import AllCamera from "./components/AllCamera";
import Configuration from "./components/Configuration";
import HeadCount from "./components/HeadCount";



const { REACT_APP_CREATE_AGREEMENT_API } = process.env
function App() {

  return (
    <BrowserRouter>
      <RenderOnAnonymous>
        <ReactNotifications />
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <ReactNotifications />
        <Navbar />
        <Routes>
          {/* <Route path="/" element={<DashBoard />} /> */}
          <Route path="/charts" element={<Charts />} />
          <Route path="/base" element={<AllCamera />} />
          <Route path="/configuration" element={<Configuration />} />
          {/* <Route path="/head-count" element={<HeadCount />} /> */}
          <Route path="/" element={<HeadCount />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </RenderOnAuthenticated>
    </BrowserRouter>
  );
}

export default App;
