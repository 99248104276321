import React, { useEffect, useState } from 'react'
import UserService from '../services/UserService'
import logo from "../assets/logo.png"
import { useNavigate } from 'react-router'
import chart from "../assets/daycount.png"
import LineChart from './LineChart';
import axios from "axios"
import { getNotification } from "../Redux/Action/action.js";
import Loader from "react-js-loader";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-apexcharts";



const { REACT_APP_ALL_CAMERA_URL, REACT_APP_REDIRECT_URI, REACT_APP_CAMERA_LINK } = process.env
const Charts = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [camera, setCamera] = useState(false)
    const [loader, setLoader] = useState(false)
    const [activeButton, setActiveButton] = useState(null);



    const [plotData, setPlotData] = useState([])
    const [options, setOptions] = useState({})
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [multipleCameraList, setMultipleCameraList] = useState([]);
    const [dynamicCameraList, setDynamicCameraList] = useState([])
    const [isVisible, setIsVisible] = useState(true)


    useEffect(() => {
        getCameraList()
    }, [])

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const click1stCam = () => {
        setTimeout(() => {
            document.getElementById("cam_0").click()
        }, 2000)
    }

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value); // Update state with the selected date
        console.log(event.target.value);

    };


    let countData = []
    const handleButtonClick = (cameraLink) => {
        setActiveButton(cameraLink);
        getCount([cameraLink]);
    };


    const getTimeSliced = (dateArray) => {
        const timeArray = dateArray.map(dateTime => {
            const time = dateTime.split(' ')[3] + ' ' + dateTime.split(' ')[4]; // Get the 3rd and 4th parts (time + AM/PM)
            return time;
        });
        return timeArray
    }


    const getCount = async (cameraIDList) => {
        if (UserService.isLoggedIn()) {
            try {
                setLoader(true)
                console.log("started");
                const getResponse = await axios.post(`${REACT_APP_ALL_CAMERA_URL}/camera_counts`,
                    {
                        camera_Id: cameraIDList,
                        datetime_start: {
                            datetime_field: selectedDate + " 00:00"
                        },
                        datetime_end: {
                            datetime_field: selectedDate + " 23:59"
                        }
                    },
                    {
                        params: {
                            user_Id: UserService.getUserId(),
                        },
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            "access_token": UserService.getToken(),
                        }
                    }
                );
                // const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    console.log("response", getResponse.data.data);

                    // const datas = [{ name: "Cam 1", data: [1, 2, 3, 4] }, { name: "Cam 2", data: [5, 6, 7, 8] },]
                    // countData = getResponse.data
                    let dataArray = []
                    getResponse.data.data.map((ele, ind) => {
                        dataArray.push({ name: "Cam " + (ind + 1), data: ele.charts[0].count })
                    })

                    const values = {
                        chart: {
                            id: `Dashboard_${UserService.getUsername}`,
                            type: "line",
                            toolbar: {
                                show: true,
                            },
                        },
                        title: {
                            text: 'Day Wise Count',
                            align: 'center',
                        },
                        plotOptions: {

                        },
                        colors: [
                            '#F79548', // Orange
                            '#23272B', // Dark Gray
                            '#3A9AD9', // Light Blue
                            '#8BC34A', // Light Green
                            '#FF4081', // Pink
                            '#673AB7', // Purple
                            '#FFEB3B', // Yellow
                            '#009688', // Teal
                            // '#E91E63', // Magenta
                            // '#4CAF50'  // Green
                        ]
                        ,
                        dataLabels: {
                            enabled: false,
                            style: {
                                fontSize: '6px',
                                fontWeight: 900
                            },
                            formatter: function (val) {
                                return val
                            }
                        },

                        xaxis: {
                            categories: getTimeSliced(getResponse.data.data[0].charts[0].time),  //time
                            // categories: [1, 2, 3, 4],  //time
                            title: {
                                text: 'Time',

                            },
                            labels: {
                                style: {
                                    fontSize: "11px",

                                },
                                rotate: -45,

                                formatter: function (val) {
                                    return val
                                }
                            },
                        },
                        yaxis: [
                            {
                                seriesName: 'Counts',
                                // opposite: true,
                                labels: {
                                    style: {
                                        fontSize: "14px",

                                    },
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                title: {
                                    text: "Counts",
                                    showAlways: true,
                                    floating: true,
                                    style: {
                                        color: "#426AB3"
                                    },

                                },
                            },
                            ,
                        ],
                        grid: {
                            show: false, // This will remove the grid lines
                        },
                    }
                    setPlotData(dataArray)
                    setOptions(values)
                }
            } catch (err) {
                setLoader(false);
                console.log("Error", err);
                if (err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/charts` });
                    }, 3000);
                }
                else
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
            }
            setLoader(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/charts` });
            }, 3000);
        }

    };


    const getCameraList = async () => {
        if (UserService.isLoggedIn()) {
            try {
                setLoader(true)
                console.log("started");
                const config = {
                    method: "get",
                    url: `${REACT_APP_ALL_CAMERA_URL}/cameras`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        // access_token: UserService.getToken(),
                    },
                    params: {
                        user_id: UserService.getUserId(),
                        // camera_Id: "None"
                    },
                };
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    setDynamicCameraList(getResponse.data)
                    click1stCam()
                    // console.log("response", getResponse.data);

                }
            } catch (err) {
                setLoader(false);
                console.log("Error", err);
                if (err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/charts` });
                    }, 3000);
                }
                else
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
            }
            setLoader(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/charts` });
            }, 3000);
        }

    };

    const selectAll = (value) => {
        setIsVisible(!isVisible)
        if (value) document.querySelectorAll(".custom-checkbox").forEach((ele) => { if (ele.checked !== true) ele.click() })
        else document.querySelectorAll(".custom-checkbox").forEach((ele) => { if (ele.checked === true) ele.click() })
    }

    return (
        <div className="min-h-screen bg-gray-50/50">
            {/* Expand Button */}
            {isCollapsed && (
                <button
                    style={{ position: "fixed", left: "-5px", top: "15vh", zIndex: "9999999" }}
                    className="z-50 p-2 btn btn-dark"
                    onClick={toggleSidebar}
                >
                    <i className="fas fa-angle-double-right ml-2"></i>
                </button>
            )}

            {/* Sidebar */}
            <div style={{ position: 'absolute', top: "15vh" }} className={isCollapsed ? "expanded bg-orange my-1 -translate-x-80 fixed " : "-translate-x-80 fixed inset-0 z-50 my-1 ml-4 w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 bg-orange expanded"}>
                <div className="relative border-b border-white/20 text-center">
                    <i
                        onClick={toggleSidebar}
                        className="fas fa-times-circle fa-2x cursor-pointer"
                        style={{
                            position: "absolute",
                            right: "-2px",
                            top: "-2px",
                            color: "white",
                            transition: "color 0.3s ease-in-out",
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.color = 'black'}
                        onMouseLeave={(e) => e.currentTarget.style.color = 'white'}
                    ></i>
                    <p className="items-center gap-4 pt-6 pb-1 px-8 mx-auto">
                        <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-white">Camera Lists</h6>
                        <p className='flex mt-4'><span className='text-sm fw-bold text-white mt-1.5'>Date Filter : </span>
                            <input className='rounded p-1 text-center ml-0.5' onChange={handleDateChange} defaultValue={selectedDate} type="date" />
                        </p>
                        {isVisible && <button
                            onClick={() => (selectAll(true))}
                            style={{ padding: "5px 55px 5px 55px" }}
                            className='btn btn-outline-dark text-white text-xs'>Select All
                        </button>}
                        {!isVisible && <button
                            onClick={() => (selectAll(false))}
                            style={{ padding: "5px 55px 5px 55px" }}
                            className='btn btn-outline-dark text-white text-xs'>Deselect All
                        </button>}
                        {
                            multipleCameraList.length >= 2 &&
                            <p className='p-1 space-y-2'>
                                <button
                                    onClick={() => (getCount(multipleCameraList))}
                                    className='btn btn-outline-dark text-white text-xs -mb-8'>Show Selected Charts
                                </button>
                            </p>}
                        {
                            multipleCameraList.length >= 2 &&
                            <p className='p-1 space-y-2'>
                                <button
                                    onClick={() => (getCount(multipleCameraList))}
                                    className='btn btn-outline-dark text-white text-xs -mb-8'>Show Selected Charts
                                </button>
                            </p>}
                    </p>
                    <button className="middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-white hover:bg-white/10 active:bg-white/30 absolute right-0 top-0 grid rounded-br-none rounded-tl-none xl:hidden" type="button">
                        <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" ariaHidden="true" className="h-5 w-5 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="m-2">
                    <ul className="mb-4 flex flex-col text-white px-5">
                        {dynamicCameraList && dynamicCameraList.map((ele, ind) => (
                            <li
                                style={{ cursor: 'pointer' }}
                                className={`transition duration-300 ease-in-out transform hover:scale-105 ${activeButton === ele.channel_id ? 'bg-black text-white' : ''}`}
                                // className="hover:bg-black hover:text-white transition duration-300 ease-in-out transform hover:scale-105"
                                key={ind}>
                                <input className='custom-checkbox' type="checkbox" onClick={(e) => {
                                    if (e.target.checked === true)
                                        setMultipleCameraList((prevState) => {
                                            let newArray = [...prevState]
                                            newArray.push(ele.channel_id)
                                            return newArray
                                        })
                                    else if (e.target.checked === false)
                                        setMultipleCameraList((prevState) => {
                                            let newArray = [...prevState]
                                            newArray = newArray.filter((newEle) => newEle !== ele.channel_id)
                                            return newArray
                                        })

                                }} style={{ cursor: 'pointer' }} />
                                <img className="mx-2 float-end mt-1" width="25" height="25" src="https://img.icons8.com/ios-filled/50/ffffff/video-call.png" alt="camera stream" />
                                <button
                                    onClick={() => handleButtonClick(ele.id)}
                                    className='m-1'
                                    type="button"
                                    id={"cam_" + ind}
                                >
                                    <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize m-0 p-0 flex items-center">
                                        {ele.name.length >= 15 ? ele.name.slice(0, 15) + "..." : ele.name}
                                    </p>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div style={{
                transition: 'margin-left 0.3s ease-in-out'
            }}
                className={`px-4 ${!isCollapsed ? 'xl:ml-80' : 'ml-5'}`}>
                {/* <nav className="block w-full max-w-full bg-transparent text-white shadow-none rounded-xl transition-all px-0 py-1">
                <div className="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
                    <div className="capitalize">
                        <nav aria-label="breadcrumb" className="w-max">
                            <ol className="flex flex-wrap items-center w-full bg-opacity-60 rounded-md bg-transparent p-0 transition-all">
                                <li className="flex items-center text-blue-gray-900 antialiased font-sans text-sm font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-light-blue-500">
                                    <a href="#">
                                        <p className="block antialiased font-sans text-sm leading-normal text-blue-900 font-normal opacity-50 transition-all hover:text-blue-500 hover:opacity-100">dashboard</p>
                                    </a>
                                    <span className="text-gray-500 text-sm antialiased font-sans font-normal leading-normal mx-2 pointer-events-none select-none mb-3">/</span>
                                </li>
                                <li className="flex items-center text-blue-900 antialiased font-sans text-sm font-normal leading-normal cursor-pointer transition-colors duration-300 hover:text-blue-500">
                                    <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-normal">home</p>
                                </li>
                            </ol>
                        </nav>
                        <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-gray-900">home</h6>
                    </div>
                    <div className="flex items-center">
                        <div className="mr-auto md:mr-4 md:w-56">
                            <div className="relative w-full min-w-[200px] h-10">
                                <input className="peer w-full h-full bg-transparent text-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500" placeholder=" " />
                                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:border-blue-500 after:border-blue-gray-200 peer-focus:after:border-blue-500">Type here</label>
                            </div>
                        </div>
                        <button className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 grid xl:hidden" type="button">
                            <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" stroke-width="3" className="h-6 w-6 text-blue-gray-500">
                                    <path fill-rule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clip-rule="evenodd"></path>
                                </svg>
                            </span>
                        </button>
                        <a href="#">
                            <button className="middle none font-sans font-bold center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 hidden items-center gap-1 px-4 xl:flex" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" className="h-5 w-5 text-blue-gray-500">
                                    <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clip-rule="evenodd"></path>
                                </svg>Hi {UserService.getFullName()}</button>
                            <button className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 grid xl:hidden" type="button">
                                <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" className="h-5 w-5 text-blue-gray-500">
                                        <path fill-rule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clip-rule="evenodd"></path>
                                    </svg>
                                </span>
                            </button>
                        </a>
                        <button className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button">
                            <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" className="h-5 w-5 text-blue-gray-500">
                                    <path fill-rule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clip-rule="evenodd"></path>
                                </svg>
                            </span>
                        </button>
                        <button aria-expanded="false" aria-haspopup="menu" id=":r2:" className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-lg text-xs text-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button">
                            <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" className="h-5 w-5 text-blue-gray-500">
                                    <path fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clip-rule="evenodd"></path>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </nav> */}
                <br />
                <br />
                <br />
                <div className='custom-chart'>
                    {/* <LineChart headcountData={countData} /> */}
                    <Chart
                        align="center"
                        options={options}
                        series={plotData}
                        type="line"
                        height={500}
                        width={'80%'}
                    />
                </div>
                <div className="mt-12">
                    <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4 d-none">
                        <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                            <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" className="w-6 h-6 text-white">
                                    <path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"></path>
                                    <path fill-rule="evenodd" d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z" clip-rule="evenodd"></path>
                                    <path d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z"></path>
                                </svg>
                            </div>
                            <div className="p-4 text-right">
                                <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Today's Money</p>
                                <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">$53k</h4>
                            </div>
                            <div className="border-t border-blue-gray-50 p-4">
                                <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                                    <strong className="text-green-500">+55%</strong>&nbsp;than last week
                                </p>
                            </div>
                        </div>
                        <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                            <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-pink-600 to-pink-400 text-white shadow-pink-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" className="w-6 h-6 text-white">
                                    <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <div className="p-4 text-right">
                                <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Today's Users</p>
                                <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">2,300</h4>
                            </div>
                            <div className="border-t border-blue-gray-50 p-4">
                                <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                                    <strong className="text-green-500">+3%</strong>&nbsp;than last month
                                </p>
                            </div>
                        </div>
                        <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                            <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-600 to-green-400 text-white shadow-green-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" className="w-6 h-6 text-white">
                                    <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z"></path>
                                </svg>
                            </div>
                            <div className="p-4 text-right">
                                <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">New Clients</p>
                                <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">3,462</h4>
                            </div>
                            <div className="border-t border-blue-gray-50 p-4">
                                <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                                    <strong className="text-red-500">-2%</strong>&nbsp;than yesterday
                                </p>
                            </div>
                        </div>
                        <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md">
                            <div className="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-orange-600 to-orange-400 text-white shadow-orange-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ariaHidden="true" className="w-6 h-6 text-white">
                                    <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z"></path>
                                </svg>
                            </div>
                            <div className="p-4 text-right">
                                <p className="block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600">Sales</p>
                                <h4 className="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900">$103,430</h4>
                            </div>
                            <div className="border-t border-blue-gray-50 p-4">
                                <p className="block antialiased font-sans text-base leading-relaxed font-normal text-blue-gray-600">
                                    <strong className="text-green-500">+5%</strong>&nbsp;than yesterday
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3"> */}
                    <div className="mb-4">
                        {/* <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
                        <div className="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 flex items-center justify-between p-6">
                            <div>
                                <h6 className="block antialiased tracking-normal font-sans text-base font-semibold leading-relaxed text-blue-gray-900 mb-1">Projects</h6>
                                <p className="antialiased font-sans text-sm leading-normal flex items-center gap-1 font-normal text-blue-gray-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" ariaHidden="true" className="h-4 w-4 text-blue-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path>
                                    </svg>
                                    <strong>30 done</strong> this month
                                </p>
                            </div>
                            <button aria-expanded="false" aria-haspopup="menu" id=":r5:" className="relative middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-blue-gray-500 hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30" type="button">
                                <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currenColor" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" ariaHidden="true" className="h-6 w-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"></path>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="p-6 overflow-x-scroll px-0 pt-0 pb-2">
                            <table className="w-full min-w-[640px] table-auto">
                                <thead>
                                    <tr>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">companies</p>
                                        </th>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">budget</p>
                                        </th>
                                        <th className="border-b border-blue-gray-50 py-3 px-6 text-left">
                                            <p className="block antialiased font-sans text-[11px] font-medium uppercase text-blue-gray-400">completion</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="flex items-center gap-4">
                                                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">Material XD Version</p>
                                            </div>
                                        </td>

                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">$14,000</p>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="w-10/12">
                                                <p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">60%</p>
                                                <div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
                                                    <div className="flex justify-center items-center h-full bg-gradient-to-tr from-blue-600 to-blue-400 text-white" style={{ width: "60%" }}></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="flex items-center gap-4">
                                                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">Add Progress Track</p>
                                            </div>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">$3,000</p>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="w-10/12">
                                                <p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">10%</p>
                                                <div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
                                                    <div className="flex justify-center items-center h-full bg-gradient-to-tr from-blue-600 to-blue-400 text-white" style={{ width: "10%" }}></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="flex items-center gap-4">
                                                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">Fix Platform Errors</p>
                                            </div>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">Not set</p>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="w-10/12">
                                                <p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">100%</p>
                                                <div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
                                                    <div className="flex justify-center items-center h-full bg-gradient-to-tr from-green-600 to-green-400 text-white" style={{ width: "100%" }}></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="flex items-center gap-4">
                                                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">Launch our Mobile App</p>
                                            </div>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">$20,500</p>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="w-10/12">
                                                <p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">100%</p>
                                                <div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
                                                    <div className="flex justify-center items-center h-full bg-gradient-to-tr from-green-600 to-green-400 text-white" style={{ width: "100%" }}></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="flex items-center gap-4">
                                                <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">Add the New Pricing Page</p>
                                            </div>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <p className="block antialiased font-sans text-xs font-medium text-blue-gray-600">$500</p>
                                        </td>
                                        <td className="py-3 px-5 border-b border-blue-gray-50">
                                            <div className="w-10/12">
                                                <p className="antialiased font-sans mb-1 block text-xs font-medium text-blue-gray-600">25%</p>
                                                <div className="flex flex-start bg-blue-gray-50 overflow-hidden w-full rounded-sm font-sans text-xs font-medium h-1">
                                                    <div className="flex justify-center items-center h-full bg-gradient-to-tr from-blue-600 to-blue-400 text-white" style={{ width: "25%" }}></div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div> */}
                    </div>
                </div>

            </div>
        </div >
    )
}

export default Charts