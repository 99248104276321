import React, { useEffect, useState } from 'react'
import Popup from './Popup'
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { getNotification } from '../Redux/Action/action'


const { REACT_APP_REDIRECT_URI, REACT_APP_ALL_CAMERA_URL } = process.env
const Configuration = () => {
    const dispatch = useDispatch()
    const [table, setTable] = useState([])
    const [handlePopup, setHandlePopup] = useState(false)
    const [tableData, setTableData] = useState([])
    const [data, setData] = useState({})


    useEffect(() => {
        getTableData()
    }, [])



    const getTableData = async () => {
        if (UserService.isLoggedIn()) {
            try {
                // setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();

                const config = {
                    method: "get",
                    url: `${REACT_APP_ALL_CAMERA_URL}/fetch_cameras_data`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        // "access_token": UserService.getToken()
                    },
                    // data: sendData,
                };
                // setHandleSubmit(true);
                console.log("Started");
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    console.log(getResponse.data)
                    setTableData(getResponse.data)
                }
            } catch (err) {
                console.log('error', err);
                // setLoaderAgreement(false)
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/configuration` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            // setLoaderAgreement(false);
        }
        else if (!UserService.isLoggedIn()) {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI}/configuration`,
                });
            }, 3000);
        }
        // setLoaderAgreement(false);
    };

    return (
        <div className='container-fluid'>
            {handlePopup && <Popup
                data={data}
                onConfirm={() => {
                    getTableData()
                    setHandlePopup(false)

                }} onCancel={() => setHandlePopup(false)} />}
            <div className="container-fluid px-5 px-1">
                <div className="relative flex flex-col w-full h-full text-slate-700 bg-white shadow-md rounded-xl bg-clip-border">
                    <div className="relative mx-4 mt-4 overflow-hidden text-slate-700 bg-white rounded-none bg-clip-border">
                        <div className="flex items-center justify-between ">
                            <div className='text-center'>
                                <h3 className="text-lg font-semibold text-slate-800">Camera Configuration List</h3>
                                {/* <p className="text-slate-500">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                            </div>
                            <div className="flex flex-col gap-2 shrink-0 sm:flex-row">
                                {/* <button
                                    className="rounded border border-slate-300 py-2.5 px-3 text-center text-xs font-semibold text-slate-600 transition-all hover:opacity-75 focus:ring focus:ring-slate-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    type="button">
                                    Select All
                                </button> */}
                                <button
                                    onClick={() => {
                                        setHandlePopup(true)
                                        setData({})
                                    }}
                                    className="flex select-none items-center gap-2 rounded bg-slate-800 py-2.5 px-4 text-xs font-semibold text-white shadow-md shadow-slate-900/10 transition-all hover:shadow-lg hover:shadow-slate-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none btn-primary"
                                    type="button">
                                    <span style={{ fontSize: "30px" }} className='-mt-1'>+</span>
                                    <img width="25" height="25" src="https://img.icons8.com/ios-filled/50/ffffff/video-call.png" alt="camera stream" />
                                    Add Camera
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="p-0 overflow-x-scroll">
                        <table className={tableData.length > 0 ? "w-full mt-4 text-left table-auto min-w-max text-center" : "d-none"}>
                            <thead>
                                <tr>
                                    <th
                                        className="p-2 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm font-normal leading-none text-slate-500">
                                            SL. No.
                                        </p>
                                    </th>
                                    <th
                                        className="p-2 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm font-normal leading-none text-slate-500">
                                            Channel ID
                                        </p>
                                    </th>
                                    <th
                                        className="p-2 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm  font-normal leading-none text-slate-500">
                                            Channel Name
                                        </p>
                                    </th>
                                    <th
                                        className="p-2 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm  font-normal leading-none text-slate-500">
                                            IP Address
                                        </p>
                                    </th>
                                    <th
                                        className="p-2 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm  font-normal leading-none text-slate-500">
                                            User Id
                                        </p>
                                    </th>
                                    <th
                                        className="p-2 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm  font-normal leading-none text-slate-500">
                                            Port Number
                                        </p>
                                    </th>
                                    <th
                                        className="p-2 pt-4 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100 text-center flex justify-content-center">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm  font-normal leading-none text-slate-500">
                                            RTSP URL
                                        </p>
                                    </th>
                                    <th
                                        className="p-2 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm  font-normal leading-none text-slate-500">
                                            Status
                                        </p>
                                    </th>
                                    <th
                                        className="p-2 transition-colors cursor-pointer border-y border-slate-200 bg-slate-50 hover:bg-slate-100">
                                        <p
                                            className="flex items-center justify-between gap-2 font-sans text-sm  font-normal leading-none text-slate-500">
                                            Edit
                                        </p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData && tableData.map((ele, ind) => (
                                        <tr key={ind}>
                                            <td className="p-2 border-b border-slate-200">
                                                {ind + 1}
                                            </td>
                                            <td className="p-2 border-b border-slate-200">
                                                {ele.channel_id}
                                            </td>
                                            <td className="p-2 border-b border-slate-200">
                                                {ele.channel_name}
                                            </td>
                                            <td className="p-2 border-b border-slate-200">
                                                {ele.ip_address}
                                            </td>
                                            <td className="p-2 border-b border-slate-200">
                                                {ele.user_id}
                                            </td>
                                            <td className="p-2 border-b border-slate-200">
                                                {ele.port_number}
                                            </td>
                                            <td
                                                spiketip-title={ele.rtsp_url} spiketip-pos="top"
                                                className="p-2 border-b border-slate-200 cursor-pointer">
                                                {ele.rtsp_url.slice(0, 20)}...
                                            </td>
                                            <td className="p-2 border-b border-slate-200">
                                                {
                                                    ele.cam_status === "active" ?
                                                        <div className="w-max">
                                                            <div
                                                                className="relative grid items-center px-2 py-1 font-sans text-xs font-bold text-green-900 uppercase rounded-md select-none whitespace-nowrap bg-green-500/20">
                                                                <span className="flex">Active
                                                                    <button className='w-2 h-2 rounded-full bg-green-500 mt-1 ml-2'></button>
                                                                </span>

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="w-max">
                                                            <div
                                                                className="relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap bg-slate-100 text-slate-500">
                                                                <span className="flex">Inactive
                                                                    <button className='w-2 h-2 rounded-full bg-red-500 mt-1 ml-2'></button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                }
                                            </td>
                                            <td>
                                                <i onClick={() => {
                                                    setHandlePopup(true)
                                                    setData(ele)
                                                }} className='fas fa-edit cursor-pointer'></i>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className={tableData.length > 0 ? 'd-none' : 'w-full text-center text-dark h-40 mt-20 text-slate-500'}>
                            Table is empty.
                        </div>
                    </div>
                    {/* <div className="flex items-center justify-between p-3">
                        <p className="block text-sm text-slate-500">
                            Page 1 of 10
                        </p>
                        <div className="flex gap-1">
                            <button
                                className="rounded border border-slate-300 py-2.5 px-3 text-center text-xs font-semibold text-slate-600 transition-all hover:opacity-75 focus:ring focus:ring-slate-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                type="button">
                                Previous
                            </button>
                            <button
                                className="rounded border border-slate-300 py-2.5 px-3 text-center text-xs font-semibold text-slate-600 transition-all hover:opacity-75 focus:ring focus:ring-slate-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                type="button">
                                Next
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div >
    )
}

export default Configuration