import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../assets/logo.png"
import { Link, useNavigate } from 'react-router-dom';
import UserService from '../services/UserService';


const { REACT_APP_REDIRECT_URI } = process.env
function Navbar() {
  const navigate = useNavigate()

  return (
    <header className="text-white body-font btn-dark p-0 sticky-top">
      <div className="container-fluid mx-auto flex flex-wrap p-3 flex-col md:flex-row items-center">
        <span>
          <img src={logo} alt="logo" style={{ width: '7vw' }} />
        </span>
        <nav className="ml-0 md:ml-auto flex flex-wrap items-center text-base justify-center">
          {/* <button onClick={() => navigate("/")} className="ml-5 sm:ml-2">Live Stream</button> */}
          <button onClick={() => navigate("/")} className="ml-5 sm:ml-2">Head Counts</button>
          <button onClick={() => navigate("/charts")} className="ml-5 sm:ml-2">Dashboard</button>
          <button onClick={() => navigate("/base")} className="ml-5 sm:ml-2">All Camera Mode</button>
          <button onClick={() => navigate("/configuration")} className="ml-5 sm:ml-2">Configuration</button>
        </nav>
        <button onClick={() => UserService.doLogout({ redirectUri: `${REACT_APP_REDIRECT_URI}` })} className="ml-auto text-base">Logout <i className='fas fa-sign-out-alt mx-1'></i></button>
      </div>
    </header>

  );
}

export default Navbar;
