import React from 'react';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';

// Register chart.js components and plugins
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    zoomPlugin // Ensure the zoom plugin is registered here
);

const LineChart = ({ countData }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Camera Headcount Over Time',
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: 'x', // Use 'x' for horizontal zooming or 'xy' for both axes
                },
            },
        },
        elements: {
            line: {
                borderWidth: 2,
            },
            point: {
                radius: 8,
            },
        },
    };

    const labels = countData.map(data => data.updated_at);
    const data = countData.map(data => data.count);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Camera Headcount',
                data,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                pointBackgroundColor: (context) => {
                    const value = context.raw;
                    return value > 30 ? 'rgba(255, 0, 0, 1)' : value > 20 ? 'rgba(0, 255, 0, 1)' : 'rgba(255, 255, 0, 1)';
                },
                pointRadius: 6,
                fill: false,
                tension: 0.3,
                segment: {
                    borderColor: (context) => {
                        const { p0, p1 } = context;
                        const startValue = p0.parsed.y;
                        const endValue = p1.parsed.y;

                        const getColor = (value) => {
                            if (value > 30) return 'rgba(255, 0, 0, 1)';
                            if (value > 20) return 'rgba(0, 255, 0, 1)';
                            return 'rgba(255, 255, 0, 1)';
                        };

                        return getColor(startValue) === getColor(endValue) ? getColor(startValue) : 'rgba(75, 192, 192, 1)';
                    },
                },
            },
        ],
    };

    return <Line data={chartData} options={options} />;
};

export default LineChart;
